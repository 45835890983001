<template>
  <v-dialog
      v-model="showAdd"
      width="500"
      persistent
      @keydown.esc="onClose"
      @keydown.enter="save"
  >
    <template v-slot:activator="{ on }">
      <v-btn @click="onNewClicked" color="secondary" dark v-on="on">
        {{ $vuetify.breakpoint.mobile ? '+' : $t("createSkill") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <span class="headline">
          {{ item.isEdit ? $t("editSkill") : $t("createSkill") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-col cols="12">
            <v-text-field
                :label="$t('name')"
                v-model="skill_name"
                color="#59D79D"
                :hide-details="nameError.length === 0"
                :error-count="nameError.length"
                :error-messages="nameError"
            ></v-text-field>
          </v-col>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-button :cancel="onClose" :message="$t('cancel')"/>
        <save-button :save="save" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';

export default {
  name: 'SkillsDialog',
  components: { SaveButton, CancelButton },
  props: ['showAdd', 'onClose', 'item', 'newItem', 'onAdded'],
  mixins: [errorDialog],
  data () {
    return {
      loading: false,
      skill_name: null,
      skills: [],
      nameError: []
    };
  },
  watch: {
    showAdd: function (value) {
      if (value && this.item && this.item.isEdit) {
        this.skill_name = this.item.skill_name;
        this.isEdit = this.item.isEdit;
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    onNewClicked () {
      this.resetErrors();
      this.reset();
      this.newItem();
    },
    reset () {
      this.skill_name = null;
    },
    resetErrors () {
      this.nameError = [];
    },
    save () {
      this.resetErrors();
      const url = `${process.env.VUE_APP_BASE_URL}/api/skills`;
      const formData = {
        skill_name: this.skill_name
      };
      if (!this.skill_name) {
        this.nameError.push(this.$t('fieldNameRequired'));
      } else {
        this.loading = true;
        if (!this.item.isEdit) {
          axios
              .post(url, formData)
              .then(() => {
                this.loading = false;
                this.onClose();
                this.onAdded();
                this.reset();
              })
              .catch((error) => {
                this.loading = false;
                this.showError(error);
              });
        } else {
          const url = `${process.env.VUE_APP_BASE_URL}/api/skills/${this.item.id}`;
          axios
              .put(url, formData)
              .then(() => {
                  this.reset();
                  this.loading = false;
                  this.onClose();
                  this.onAdded();
              })
              .catch((error) => {
                this.loading = false;
                this.showError(error);
              });
        }
      }
    }
  }
};
</script>
