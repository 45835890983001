<template>
  <v-main class="container-prosker">
    <v-data-table
        :options.sync="pagination"
        :server-items-length="pagination.totalItems"
        :loading="loadingTable"
        :footer-props="{itemsPerPageText: $t('rowsPerPage')}"
        :search="search"
        :headers="headers"
        :items="skillsList"
        class="elevation-1 mt-5"
        item-key="id"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('skills') }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field :label="$t('search')" append-icon="search" hide-details
                          single-line v-model="search" color="#59D79D"></v-text-field>
            <v-spacer></v-spacer>
            <skills-dialog
                :item="editedItem"
                :on-close="onAddClose"
                :on-added="onAddCallback"
                :new-item="newItem"
                :show-add="showAdd"
            />
          </v-toolbar>
        </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="warning">
          <span class="white--text">
            {{ $t('yourSearchFor') }} "{{ search }}" {{ $t('foundNoResults') }}
          </span>
        </v-alert>
      </template>
      <template v-slot:no-data>
          <v-alert
              :value="true"
              color="error"
              icon="warning"
              class="white--text"
          >
            <span class="white--text">
              {{ $t("noData") }}
            </span>
          </v-alert>
        </template>
        <template v-slot:header.skill_name="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:header.action="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:item.action="{ item }">
          <tooltip-button
              :icon="'mdi-pencil'"
              :action="editItem"
              :item="item"
              :color="'secondary'"
              :message="'edit'"
          ></tooltip-button>
        </template>
      </v-data-table>
  </v-main>
</template>
<script>
import axios from 'axios';
import TooltipButton from '@/components/socialvue/buttons/TooltipButton.vue';
import SkillsDialog from '@/components/socialvue/dialogs/SkillsDialog.vue';
import _ from 'lodash';

export default {
  name: 'SkillsList',
  components: { SkillsDialog, TooltipButton },
  data () {
    return {
      skillsList: [],
      showAdd: false,
      editedItem: {},
      pagination: { totalItems: 0, per_page: 10, page: 1, sortBy: ['id'] },
      loadingTable: false,
      search: '',
      headers: [
        { text: 'skills', value: 'skill_name' },
        { text: 'actions', value: 'action', sortable: false }
      ]
    };
  },
  created () {
    this.list();
  },
  watch: {
    search: {
      handler () {
        this.resetPaginationForSearch(this);
      },
      deep: true
    },
    pagination: {
      handler () {
        this.list();
      },
      deep: true
    }
  },
  methods: {
    resetPagination () {
      this.pagination = { totalItems: 0, per_page: 10, page: 1, sortBy: ['id'] };
    },
    resetPaginationForSearch: _.debounce((self) => {
      self.resetPagination();
    }, 500),
    list () {
      this.loadingTable = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/skills?search=${this.search}
      &per_page=${this.pagination.per_page}&page=${this.pagination.page}&sortBy=${this.pagination.sortBy[0]}`;
      axios.get(url).then((response) => {
        this.skillsList = [];
        this.skillsList = response.data.data;
        this.pagination.totalItems = response.data.total;
        this.loadingTable = false;
      }).catch((error) => {
        this.loadingTable = false;
        this.showError(error);
      });
    },
    onAddClose () {
      this.showAdd = false;
      this.reset();
      this.editedItem.isEdit = false;
    },
    onAddCallback () {
      this.list();
    },
    reset () {
      this.editedItem = {};
    },
    newItem () {
      this.reset();
      this.showAdd = true;
      this.editedItem.isEdit = false;
    },
    editItem (item) {
      this.editedItem = item;
      this.editedItem.isEdit = true;
      this.showAdd = true;
    }
  }
};
</script>
